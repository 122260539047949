import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import WheelIcon from '../../assets/icons/wheel.inline.svg';
import ArrowLeft from '../../assets/icons/arrow-left.inline.svg';
import ArrowRight from '../../assets/icons/arrow-right.inline.svg';
import MotorImage from '../../assets/images/motor.png';
import theme from '../../styles/themes';

const DimmedText = styled(Typography)`
  color: rgba(214, 214, 214, 0.3);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const InfoContainer = styled(Box)`
  > div {
    padding: 60px 30px 30px 30px;
  }
  ${theme.breakpoints.up('md')} {
    max-width: 600px;
    > div {
      padding: 40px 40px 40px 80px;
    }
  }
  ${theme.breakpoints.up('lg')} {
    max-width: 722px;
    > div {
      padding: 65px 60px 60px 120px;
    }
  }
`;

const ImageContainer = styled(Box)`
  height: 300px;
  width: 85%;
  margin: auto auto -30px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  ${theme.breakpoints.up('md')} {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 85%;
    width: 400px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 540px;
  }
`;

const Motor = () => {
  return (
    <Box position="relative">
      <ContentContainer>
        <Box position="relative">
          <Box position="relative" zIndex="1">
            <Typography style={{ fontWeight: '500' }} gutterBottom variant="h1">
              <FormattedHTMLMessage id="homepageMotorTitle" />
            </Typography>
          </Box>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0" zIndex="0">
              <DimmedText>
                <FormattedHTMLMessage id="homepageMotorDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box position="relative" zIndex="1">
          <Grid container justify="flex-end">
            <ImageContainer>
              <ResponsiveImage
                style={{ backgroundImage: `url(${MotorImage})` }}
              />
            </ImageContainer>
            <InfoContainer>
              <Box
                bgcolor="#2C2C2C"
                borderRadius="16px"
                boxShadow="0px 8px 6px rgba(0, 0, 0, 0.25)"
                color="#ffffff"
              >
                <Typography variant="h3" gutterBottom>
                  <FormattedHTMLMessage id="homepageMotorContentTitle" />
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '80px' }}>
                  <FormattedHTMLMessage id="homepageMotorContentText" />
                </Typography>
                <Grid
                  style={{ marginBottom: '64px' }}
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <Box>
                    <Grid container alignItems="center">
                      <WheelIcon style={{ marginRight: '10px' }} />
                      <Typography variant="body2">
                        <FormattedHTMLMessage id="homepageMotorContent1" />
                      </Typography>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container alignItems="center">
                      <WheelIcon style={{ marginRight: '10px' }} />
                      <Typography variant="body2">
                        <FormattedHTMLMessage id="homepageMotorContent2" />
                      </Typography>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container alignItems="center">
                      <WheelIcon style={{ marginRight: '10px' }} />
                      <Typography variant="body2">
                        <FormattedHTMLMessage id="homepageMotorContent3" />
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  style={{ color: '#8D8D8D' }}
                >
                  <ArrowLeft />
                  <Box mx="40px">
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: '#FF0844', marginRight: '10px' }}
                    >
                      2
                    </Typography>
                    <Typography component="span" variant="body2">
                      / 4
                    </Typography>
                  </Box>
                  <ArrowRight />
                  <Typography variant="body2" style={{ marginLeft: '10px' }}>
                    <FormattedHTMLMessage id="headerInverter" />
                  </Typography>
                </Grid>
              </Box>
            </InfoContainer>
          </Grid>
        </Box>
      </ContentContainer>
      <Box
        bottom="0"
        width="100%"
        height="50%"
        bgcolor="#0A0C0E"
        position="absolute"
        zIndex="0"
      />
    </Box>
  );
};

export default Motor;
