import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import NewsImage from '../../assets/images/homepage-news.png';
import NewsItem from './NewsItem';
import FeedCarousel from '../../components/FeedCarousel';

const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('md')} {
    padding: 108px 0 168px;
  }
`;

const DimmedText = styled(Typography)`
  color: rgba(93, 138, 255, 0.1);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const News = () => {
  const intl = useIntl();
  const query = useStaticQuery(graphql`
    query QueryFeed {
      allStrapiSocialFeed {
        nodes {
          title
          link
          locale
          date(formatString: "D / M / YYYY")
          summary
          strapiId
        }
      }
    }
  `);

  return (
    <ResponsiveImage
      style={{
        backgroundImage: `url(${NewsImage})`,
        height: 'auto',
        backgroundSize: 'contain',
        backgroundColor: '#1c1534',
      }}
    >
      <Content>
        <Box position="relative" color="#ffffff">
          <Box position="relative" zIndex="1">
            <Typography
              style={{ fontWeight: '500', marginBottom: '60px' }}
              variant="h1"
            >
              <FormattedHTMLMessage id="homepageNewsTitle" />
            </Typography>
          </Box>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0" zIndex="0">
              <DimmedText>
                <FormattedHTMLMessage id="homepageNewsDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <FeedCarousel
          slides={query.allStrapiSocialFeed.nodes.filter(
            (item) => item.locale === intl.locale
          )}
          SlideComponent={NewsItem}
        />
      </Content>
    </ResponsiveImage>
  );
};

export default News;
