import React from 'react';
import { Box, Button, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import SolutionsImage from '../../assets/images/solutions.png';
import theme from '../../styles/themes';

const ExploreButton = styled(Button)`
  background: linear-gradient(
    97.31deg,
    #1a91ff 2.63%,
    #5c00f2 79.1%,
    #9e24fd 108.63%
  );
  box-shadow: 0px 3px 3px rgba(31, 0, 83, 0.4),
    -2px -3px 11px rgba(0, 111, 213, 0.1), 4px 3px 10px rgba(103, 33, 214, 0.3),
    inset 0px 1px 0px rgba(255, 255, 255, 0.7);
  border-radius: 51px;
  padding: 10px 15px;
  color: #ffffff;
`;

const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('md')} {
    padding: 108px 0 168px;
  }
`;

const DimmedText = styled(Typography)`
  color: rgba(93, 138, 255, 0.1);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const BackgroundImage = styled(ResponsiveImage)`
  ${theme.breakpoints.up('lg')} {
    background-size: contain;
  }
`;

const Solutions = () => {
  return (
    <BackgroundImage
      style={{
        backgroundImage: `url(${SolutionsImage})`,
        height: 'auto',
        backgroundColor: '#0A0C0E',
      }}
    >
      <Content>
        <Box position="relative" color="#ffffff">
          <Box position="relative" zIndex="1">
            <Typography
              style={{ fontWeight: '500', marginBottom: '60px' }}
              variant="h1"
            >
              <FormattedHTMLMessage id="homepageSolutionsTitle" />
            </Typography>
          </Box>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0" zIndex="0">
              <DimmedText>
                <FormattedHTMLMessage id="homepageSolutionsDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box maxWidth="710px" color="#ffffff">
          <Typography variant="body1" style={{ marginBottom: '40px' }}>
            <FormattedHTMLMessage id="homepageSolutionsText" />
          </Typography>
        </Box>
        <ExploreButton>
          <Typography variant="body1" style={{ fontWeight: '500' }}>
            <FormattedHTMLMessage id="homepageSolutionsButton" />
          </Typography>
        </ExploreButton>
      </Content>
    </BackgroundImage>
  );
};

export default Solutions;
