import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import { ContentContainer } from '../../styles/styled';
import IntegrationImage from '../../assets/images/integration.png';
import theme from '../../styles/themes';

const FirstMotor = styled.img`
  width: auto;
  ${theme.breakpoints.up('lg')} {
    margin-left: -50px;
  }
`;

const SecondMotor = styled.img`
  width: auto;
  ${theme.breakpoints.up('lg')} {
    margin-left: -70px;
  }
  margin-top: -10px;
`;

const ThirdMotor = styled.img`
  width: auto;
  ${theme.breakpoints.up('sm')} {
    margin-left: -30px;
  }
  ${theme.breakpoints.up('lg')} {
    margin-left: -110px;
  }
`;

const FourthMotor = styled.img`
  width: auto;
  ${theme.breakpoints.up('lg')} {
    margin-left: -80px;
  }
  margin-top: -40px;
`;

const MotorGrid = styled(Grid)`
  justify-content: 'center';
  ${theme.breakpoints.up('md')} {
    max-width: 600px;
  }
  ${theme.breakpoints.up('lg')} {
    max-width: none;
  }
`;

const DimmedText = styled(Typography)`
  color: rgba(214, 214, 214, 0.3);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const FirstText = styled(Typography)`
  color: #ffffff;
  ${theme.breakpoints.up('md')} {
    color: #000000;
  }
`;

const Powertrain = () => {
  return (
    <Box position="relative">
      <ContentContainer>
        <Box position="relative">
          <Box position="relative" zIndex="1">
            <Typography
              style={{ fontWeight: '500', wordBreak: 'break-word' }}
              gutterBottom
              variant="h1"
            >
              <FormattedHTMLMessage id="homepagePowertrainTitle" />
            </Typography>
          </Box>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0" zIndex="0">
              <DimmedText>
                <FormattedHTMLMessage id="homepagePowertrainDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box position="relative" zIndex="1">
          <Grid container spacing={6} justify="space-between">
            <Grid item>
              <MotorGrid container alignItems="center" justify="center">
                <img
                  src={IntegrationImage}
                  alt="przekształtnik energoeletroniczny"
                />
              </MotorGrid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <Grid container direction="column" spacing={6}>
                <Grid item>
                  <Grid container wrap="nowrap">
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#6B6B6B' }}
                      >
                        01
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FirstText style={{ fontSize: '1rem', margin: '0 20px' }}>
                        <FormattedHTMLMessage id="homepagePowertrain1" />
                      </FirstText>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item>
                  <Grid container wrap="nowrap">
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#6B6B6B' }}
                      >
                        02
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: '1rem',
                          margin: '0 20px',
                          color: '#ffffff',
                        }}
                      >
                        <FormattedHTMLMessage id="homepagePowertrain2" />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ContentContainer>
      <Box
        bottom="0"
        width="100%"
        height="42%"
        bgcolor="#1C1534"
        position="absolute"
        zIndex="0"
      />
    </Box>
  );
};

export default Powertrain;
