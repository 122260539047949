import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import { INewsItem } from '.';
import { Text } from '../../styles/styled';

import CloseIcon from '@material-ui/icons/Close';

interface INewsItemProps {
  data: INewsItem;
}

const Container = styled(Box)`
  box-shadow: 0px 6px 18px rgba(9, 0, 102, 0.3);
  border-radius: 16px;
  background: linear-gradient(
    246.05deg,
    rgba(255, 255, 255, 0.34) 12.19%,
    rgba(255, 255, 255, 0.2) 93.58%
  );
  backdrop-filter: blur(8px);
  padding: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
`;

const ReadButton = styled(Button)`
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(
    97.31deg,
    #1a91ff 2.63%,
    #5c00f2 79.1%,
    #9e24fd 108.63%
  );
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.7);

  &:hover {
    opacity: 0.8;
  }
`;

const NewsItem: React.FC<INewsItemProps> = ({ data, ...rest }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      {...rest}
    >
      <Box>
        <Typography
          variant="h6"
          style={{
            fontWeight: '500',
            lineHeight: '21.09px',
            marginBottom: '10px',
          }}
        >
          {data.title}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: '400',
            lineHeight: '16.41px',
            marginBottom: '10px',
          }}
        >
          {data.date}
        </Typography>
        <Typography
          variant="body1"
          style={{ lineHeight: '21.09px', marginBottom: '20px' }}
        >
          {data.summary}
        </Typography>
      </Box>
      <ReadButton variant="contained" onClick={() => setOpen(true)}>
        Read all
      </ReadButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle disableTypography>
          <Grid
            container
            spacing={1}
            wrap="nowrap"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Text variant="h5">{data.title}</Text>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                style={{ padding: '0' }}
              >
                <CloseIcon style={{ color: '#000000' }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Text variant="body1">{data.summary}</Text>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default NewsItem;
