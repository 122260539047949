import React from 'react';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { Banner, Motor, Powertrain, Solutions, News } from '../screens/HomePage';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Banner />
      <Motor />
      <Solutions />
      <Powertrain />
      <News />
    </Layout>
  );
};

export default IndexPage;
