import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import BannerImage from '../../assets/images/homepage-banner.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';

const Content = styled(ContentContainer)`
  padding: 60px 1.25rem;
  ${theme.breakpoints.up('md')} {
    padding: 180px 0;
  }
`;

const DimmedText = styled(Typography)`
  color: rgba(147, 162, 174, 0.2);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const Banner = () => {
  return (
    <ResponsiveImage
      style={{
        backgroundColor: '#000000',
        backgroundImage: `url(${BannerImage})`,
        height: 'auto',
      }}
    >
      <Content>
        <Box maxWidth="375px" position="relative" color="#ffffff">
          <Typography
            variant="h1"
            gutterBottom
            style={{ zIndex: '1', position: 'relative' }}
          >
            <FormattedHTMLMessage id="homepageBannerTitle" />
          </Typography>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0">
              <DimmedText>
                <FormattedHTMLMessage id="homepageBannerDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box maxWidth="480px" color="#ffffff">
          <Typography variant="body1" style={{ letterSpacing: '1px' }}>
            <FormattedHTMLMessage id="homepageBannerText" />
          </Typography>
        </Box>
      </Content>
    </ResponsiveImage>
  );
};

export default Banner;
