import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDebouncedFn, useWindowResize } from 'beautiful-react-hooks';
import { useKeenSlider } from 'keen-slider/react';

import { ArrowButton, Carousel, ItemsContainer } from './styled';
import { IFeedCarouselProps } from './index';

import ArrowLeft from '../../assets/svg/arrow-left.inline.svg';
import ArrowRight from '../../assets/svg/arrow-right.inline.svg';

const FeedCarousel: React.FC<IFeedCarouselProps> = ({
  slides,
  SlideComponent,
}) => {
  const [maxLength, setMaxLength] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: 1,
    spacing: 30,
    mode: 'snap',
    breakpoints: {
      '(min-width: 960px)': {
        slidesPerView: 2,
        mode: 'free-snap',
      },
      '(min-width: 1280px)': {
        slidesPerView: 3,
        mode: 'free-snap',
      },
    },
    slideChanged: (s) => {
      setCurrentSlide(s.details().absoluteSlide + 1);
    },
  });
  const [carouselWidth, setCarouselWidth] = useState<string | number>('500px');
  const containerRef = useRef(null);
  const containerCallback = useCallback(
    (node) => {
      if (node !== null) {
        setCarouselWidth(window.innerWidth - node.getBoundingClientRect().left);
        containerRef.current = node;
      }
      if (slider) {
        setMaxLength(
          Math.max(
            slider.details().size - slider.details().slidesPerView + 1,
            1
          )
        );
      }
    },
    [slider]
  );

  const handleWindowResize = useDebouncedFn(() => {
    if (containerRef.current !== null) {
      setCarouselWidth(
        window.innerWidth - containerRef.current.getBoundingClientRect().left
      );
    }
    if (slider) {
      setMaxLength(
        Math.max(slider.details().size - slider.details().slidesPerView + 1, 1)
      );
    }
  }, 100);
  useWindowResize(handleWindowResize);

  return (
    <>
      <Box position="relative" height="100%" ref={containerCallback} mb="40px">
        <Carousel width={carouselWidth}>
          <ItemsContainer>
            <div ref={sliderRef} className="keen-slider">
              {slides.map((slide) => (
                <SlideComponent
                  key={slide.title + slide.strapiId}
                  data={slide}
                  className="keen-slider__slide"
                />
              ))}
            </div>
          </ItemsContainer>
        </Carousel>
      </Box>
      <Box
        color="#D4D2F7"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="150px"
      >
        <ArrowButton onClick={() => slider.prev()}>
          <ArrowLeft />
        </ArrowButton>
        <Typography variant="body2">
          <span style={{ color: '#FF0844', marginRight: '10px' }}>
            {currentSlide}
          </span>
          {`/ ${maxLength}`}
        </Typography>
        <ArrowButton onClick={() => slider.next()}>
          <ArrowRight />
        </ArrowButton>
      </Box>
    </>
  );
};

export default FeedCarousel;
