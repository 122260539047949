import styled from 'styled-components';
import { Box, IconButton } from '@material-ui/core';

export const Carousel = styled(Box)`
  z-index: 1;
  overflow: hidden;

  .keen-slider {
    overflow: visible;
  }
`;

export const ItemsContainer = styled(Box)`
  width: 280px;

  @media only screen and (min-width: 600px) {
    width: 480px;
  }

  @media only screen and (min-width: 960px) {
    width: 900px;
  }

  @media only screen and (min-width: 1280px) {
    width: 1240px;
  }

  @media only screen and (min-width: 1800px) {
    width: 1500px;
  }
`;

export const ArrowButton = styled(IconButton)`
  padding: 0;
`;
